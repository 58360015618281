// src/auth.js
import Cookies from 'js-cookie';
import { generatePKCECodes } from '../web-chat/utils/pkce';
import { encryptMessage } from '../web-chat/utils/encryption';

const setCookie = (name, value) => {
  Cookies.set(name, value, { secure: true, sameSite: 'Strict', expires: 1 });
};

const getCookie = (name) => {
  return Cookies.get(name);
};

const removeCookie = (name) => {
  Cookies.remove(name, { secure: true, sameSite: 'Strict' });
};

export const login = async (oauthConfigs) => {
  const { clientId, responseType, scope, authorizationEndpoint, redirectEndpoint, realm, audience, responseMode } = oauthConfigs;
  const { codeVerifier, codeChallenge } = await generatePKCECodes();
  setCookie('codeVerifier', codeVerifier);  

  const params = {
    response_type: responseType,
    client_id: clientId,
    redirect_uri: redirectEndpoint,
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
    ...(realm && {realm: realm}),
    ...(scope && {scope: scope}),
    ...(audience && {audience: audience}),
    ...(responseMode && {response_mode: responseMode})
  };

  const url = `${authorizationEndpoint}?${new URLSearchParams(params).toString()}`;
  
  window.location.href = url;
};

export const handleCallback = async (oauthConfigs) =>  {
  const response = await fetch('api/app-config');
  const {webApiUrl} = await response.json();

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code')

  const resumeDetails = JSON.parse(getCookie('oauth'));

  const codeVerifier = getCookie('codeVerifier');

  const encryptedVerified = encryptMessage(codeVerifier, oauthConfigs.publicKey);
  const encryptedCode = encryptMessage(code, oauthConfigs.publicKey);

  const requestBody = {
    CodeVerifier : encryptedVerified, 
    Code: encryptedCode,
    ResumeCodeId: resumeDetails.resumeId
  }

  await fetch(`${webApiUrl}/api/OauthToken/token`, {
    method: 'Post',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify(requestBody)
  });
  
  window.history.replaceState({}, document.title, '/');
};


export const logout = () => {
  removeCookie('access_token');
  removeCookie('id_token');
  removeCookie('codeVerifier');
  removeCookie('paramAuthentication');
  removeCookie('oauth');
};

export const isAuthenticated = () => {
  return getCookie('access_token') !== null;
};

export const getAccessToken = () => {
  return getCookie('access_token');
};
